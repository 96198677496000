import FETCH_COURSES from "../../graphql/query/fetchCourses.gql";
import FETCH_RECOMMENDED_COURSES from "../../graphql/query/fetchRecommendedCourses.gql";

export const namespaced = true;

const setState = () => ({
  courses: [],
  recommendedCourses: [],
});

export const state = setState();

export const mutations = {
  SET_COURSES: (state, courses) => (state.courses = courses),
  SET_RECOMMENDED_COURSES: (state, recommendedCourses) =>
    (state.recommendedCourses = recommendedCourses),
  NEW_COURSE: (state, course) => state.courses.push(course),
  TOGGLE_BOOKMARK: (state) => (state.isBookmarked = state),
};

export const actions = {
  async fetchCourses({ commit }, { apolloClient }) {
    try {
      const {
        data: { FetchCourses },
      } = await apolloClient.query({
        query: FETCH_COURSES,
        fetchPolicy: "no-cache",
      });
      commit("SET_COURSES", FetchCourses);
      return true;
    } catch (error) {
      console.error(error);
    }
  },
  async fetchRecommendedCourses({ commit }, { apolloClient }) {
    try {
      const {
        data: { FetchRecommendedCourses },
      } = await apolloClient.query({
        query: FETCH_RECOMMENDED_COURSES,
        fetchPolicy: "no-cache",
      });
      commit("SET_RECOMMENDED_COURSES", FetchRecommendedCourses);
      return true;
    } catch (error) {
      console.error(error);
    }
  },
  async addCourse({ commit }, course) {
    // Simulate an API call
    const response = await new Promise((resolve) => {
      setTimeout(() => {
        resolve(course);
      }, 1000);
    });
    commit("newCourse", response);
  },
};

export const getters = {
  allCourses: (state) => state.courses,
  recommendedCourses: (state) => state.recommendedCourses,
  courseById: (state) => (documentId) =>
    state.courses.find((course) => course.documentId === documentId),
};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced,
};
