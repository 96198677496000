import moment from "moment-timezone";
import store from "../../store";
import { createClient } from "../../vue-apollo";
import cache from "../cache";

const guards = [
  {
    path: "/",
    name: "Home",
    meta: {
      layout: "Main",
      requires: {
        auth: true,
        verified: true,
        wizardStep: 4,
        groups: ["sysadmin", "collaborator", "customer"],
      },
    },
    component: () => import(/* webpackChunkName: "main" */ "@/pages/Home.vue"),
    beforeEnter(to, from, next) {
      const userGroup = store.getters["users/userGroup"];
      const activeRole = store.getters["users/activeRole"];
      let path = cache.get();
      if (!!path) next(path).catch(() => {});
      if (userGroup === "sysadmin") {
        next({ name: "Dashboard" });
        return;
      }
      if (
        (userGroup === "customer" && activeRole === "admin") ||
        (userGroup === "collaborator" && activeRole === "consultant")
      ) {
        next({ name: "Dashboard-BI" });
        return;
      }
      next({ name: "Sessions" });
    },
  },
  {
    path: "/chat",
    name: "Chat",
    props: true,
    meta: {
      layout: "Main",
      requires: {
        auth: true,
        verified: true,
        activeRole: true,
        groups: ["collaborator", "customer"],
        roles: ["coach", "coachee"],
        checkRole: true,
      },
    },
    component: () => import(/* webpackChunkName: "main" */ "@/pages/Chat.vue"),
  },
  {
    path: "/asistente",
    alias: "/wizard",
    name: "Wizard",
    meta: {
      layout: "Main",
      requires: {
        auth: true,
        verified: true,
        activeRole: true,
        groups: ["collaborator", "customer"],
        checkRole: true,
        roles: ["coach", "coachee"],
      },
    },
    component: () => import(/* webpackChunkName: "wizard" */ "@/pages/Wizard.vue"),
    beforeEnter(to, from, next) {
      // "Fix" welcomeStepper no actualizando a paso 3 desde api
      if (from.path === "/authorization" && from.query.code) {
        store.commit("users/SET_WELCOME_STEPPER", 3);
      }
      next();
    },
  },
  {
    path: "/sesiones",
    alias: "/sessions",
    name: "Sessions",
    meta: {
      layout: "Main",
      requires: {
        auth: true,
        verified: true,
        wizardStep: 3,
        activeRole: true,
        groups: ["collaborator", "customer"],
        roles: ["coach", "coachee"],
        checkRole: true,
      },
    },
    component: () => import(/* webpackChunkName: "main" */ "@/pages/Sessions.vue"),
  },
  {
    path: "/agendar",
    alias: "/schedule",
    name: "Schedule",
    props: true,
    meta: {
      layout: "v2/Main",
      requires: {
        auth: true,
        verified: true,
        wizardStep: 2,
        groups: ["customer"],
        roles: ["coachee"],
      },
    },
    component: () =>
      import(/* webpackChunkName: "selection" */ "@/pages/Schedule.vue"),
  },
  {
    path: "/agendar/exito",
    alias: "/schedule/success",
    name: "ScheduleSuccess",
    props: true,
    meta: {
      layout: "v2/Main",
      requires: {
        auth: true,
        verified: true,
        wizardStep: 4,
        groups: ["customer"],
        roles: ["coachee"],
      },
    },
    component: () =>
      import(/* webpackChunkName: "selection" */ "@/pages/ScheduleSuccess.vue"),
    beforeEnter(to, from, next) {
      if (!to.params.coach || !to.params.datetime) next({ name: "Wizard" });
      next();
    },
  },
  {
    path: "/encuesta",
    alias: "/quiz",
    name: "Quiz",
    meta: {
      layout: "Main",
      requires: {
        auth: true,
        verified: true,
        checkRole: true,
        wizardStep: 1,
        groups: ["customer"],
      },
    },
    component: () =>
      import(/* webpackChunkName: "coacheeWizard" */ "@/pages/Coachee/Quiz.vue"),
  },
  {
    path: "/escoger-coach",
    alias: "/pick-coach",
    name: "PickCoach",
    meta: {
      layout: "v2/Main",
      requires: {
        auth: true,
        verified: true,
        wizardStep: 2,
        activeRole: true,
        groups: ["customer"],
        roles: ["coachee"],
      },
    },
    component: () =>
      import(/* webpackChunkName: "coacheeWizard" */ "@/pages/PickCoach.vue"),
  },
  {
    path: "/perfil",
    alias: "/profile",
    name: "Profile",
    meta: {
      layout: "Main",
      requires: {
        auth: true,
        verified: true,
        groups: ["sysadmin", "collaborator", "customer"],
        checkRole: true,
        wizardStep: 4,
      },
    },
    component: () => import(/* webpackChunkName: "profile" */ "@/pages/Profile.vue"),
  },

  {
    path: "/configuraciones",
    alias: "/settings",
    name: "Settings",
    meta: {
      layout: "Main",
      requires: {
        auth: true,
        verified: true,
        groups: ["sysadmin", "collaborator", "customer"],
        wizardStep: store.getters["users/userRole"] === "coachee" ? 4 : 1,
      },
    },
    component: () =>
      import(/* webpackChunkName: "settings" */ "@/pages/Settings.vue"),
  },
  {
    path: "/calendarios",
    alias: "/calendars",
    name: "Calendars",
    meta: {
      layout: "Main",
      requires: {
        auth: true,
        verified: true,
        wizardStep: 3,
        groups: ["collaborator"],
      },
    },
    props: ({ query: { code, state }, params }) => ({
      code,
      state,
      ...params,
    }),
    component: () =>
      import(/* webpackChunkName: "calendar" */ "@/pages/Calendars.vue"),
  },
  {
    path: "/disponibilidad",
    alias: "/availability",
    meta: {
      layout: "Main",
      requires: {
        auth: true,
        verified: true,
        wizardStep: 3,
        groups: ["collaborator"],
      },
    },
    component: () =>
      import(/* webpackChunkName: "calendar" */ "@/pages/Availability.vue"),
  },
  {
    path: "/autorizacion",
    alias: "/authorization",
    name: "Authorization",
    meta: {
      layout: "Main",
      requires: {
        auth: true,
        verified: true,
      },
    },
    props: ({ query: { code, state, redirect, completed } }) => ({
      code,
      state,
      redirect,
      completed,
    }),
    component: () =>
      import(/* webpackChunkName: "auth" */ "@/pages/Authorization.vue"),
  },
  {
    path: "/conferencia",
    alias: "/conference",
    name: "Conference",
    meta: {
      layout: "Flow",
      requires: {
        auth: true,
        verified: true,
        groups: ["collaborator", "customer"],
      },
    },
    props: ({ query: { room, metadata, external } }) => ({
      room,
      metadata,
      external: String(external).toLowerCase() === "true",
    }),
    async beforeEnter(to, from, next) {
      try {
        const deniedStatesConference =
          process.env?.VUE_APP_DENIED_STATES_CONFERENCE?.split(",") || [
            "cancelled_on_time",
            "cancelled_off_time",
            "unfinished_without_parties",
          ];
        const user = store.getters["users/user"];
        let session = store.getters["schedules/getSessionList"].find(
          (s) => s._id === to.query.metadata
        );

        if (!session) {
          await store.dispatch("schedules/fetchSessionList", {
            apolloClient: createClient(),
            data: {},
          });
          session = store.getters["schedules/getSessionList"].find(
            (s) => s._id === to.query.metadata
          );
        }
        if (String(to.query.external) === "true") {
          await store.dispatch("schedules/sessionLogs", {
            apolloClient: createClient(),
            logs: JSON.stringify({
              [user._id]: {
                [moment().format()]: "external_join",
              },
            }),
            sessionId: session._id,
          });
          window.location.replace(to.query.room);
          next("/");
        }

        if (
          !session ||
          session[user._type.active]._id !== user._id ||
          deniedStatesConference.includes(session.state)
        ) {
          store.dispatch("notifications/callSnackbar", {
            color: "error",
            content: `La Video Sesión no esta activa. Si tienes dudas contactar al equipo de soporte`,
            contentClass: "",
            timeout: 6000,
          });
          next("/");
        }

        next();
      } catch (error) {
        console.error(error);
        store.dispatch("notifications/callSnackbar", {
          color: "error",
          content: "Error al entrar.",
        });
        next("/");
      }
    },
    component: () =>
      import(/* webpackChunkName: "conference" */ "@/pages/Conference.vue"),
  },
  {
    path: "/formulario",
    alias: "/form",
    name: "EmbedForms",
    props: true,
    component: () =>
      import(/* webpackChunkName: "conference" */ "@/pages/EmbedForms.vue"),
  },
  {
    path: "/dashboard/:programId?",
    name: "Dashboard-BI",
    meta: {
      layout: "v2/Main",
      requires: {
        auth: true,
        verified: true,
        activeRole: true,
        groups: ["customer", "collaborator"],
        roles: ["admin", "consultant"],
      },
    },
    component: () =>
      import(
        /* webpackChunkName: "sysadmin" */ "@/pages/CustomerAdmin/Dashboard.vue"
      ),
  },
  {
    path: "/aceleradores",
    alias: "/accelerators",
    name: "Accelerators",
    props: true,
    meta: {
      layout: "v2/Main",
      requires: {
        auth: true,
        verified: true,
        groups: ["customer"],
        roles: ["coachee"],
      },
    },
    component: () =>
      import(/* webpackChunkName: "accelerators" */ "@/pages/Accelerators.vue"),
  },
  {
    path: "/academy",
    alias: "/academy",
    name: "AcademyHome",
    props: true,
    meta: {
      layout: "AcademyCatalog",

      requires: {
        auth: true,
        verified: true,
        activeRole: true,
        groups: ["customer"],
        checkRole: true,
        roles: ["coachee"],
      },
    },
    component: () =>
      import(/* webpackChunkName: "wizard" */ "@/pages/Academy/AcademyHome.vue"),
  },
  {
    path: "/academy/curso/:documentId",
    alias: "/academy/course/:documentId",
    name: "AcademyCurseContent",
    meta: {
      layout: "BlankAcademy",
      requires: {
        auth: true,
        verified: true,
        activeRole: true,
        groups: ["customer"],
        checkRole: true,
        roles: ["coachee"],
      },
    },
    component: () =>
      import(
        /* webpackChunkName: "wizard" */ "@/pages/Academy/AcademyCourseContent.vue"
      ),
    async beforeEnter(to, from, next) {
      try {
        const apolloClient = createClient();
        const response = await store.dispatch("courses/fetchCourses", {
          apolloClient,
        });
        if (!response) {
          next({ name: "AcademyHome" });
        }
        next();
      } catch (error) {
        console.error(error);
        next({ name: "AcademyHome" });
      }
    },
  },
];
export default guards;
